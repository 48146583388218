var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('div',{staticClass:"table-wrapper"},[_c('shopee-table',{class:_vm.isLoading ? 'table loading' : 'table',attrs:{"loading":_vm.isLoading,"data":_vm.isLoading ? [] : _vm.list},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('table-empty',{attrs:{"type":"empty-result","text":_vm.emptySearchText}})]},proxy:true},{key:"loading",fn:function(){return [_c('skeleton')]},proxy:true}])},[_c('shopee-table-column',{attrs:{"label":"ID","prop":"id","width":50 + 16}}),_c('shopee-table-column',{attrs:{"label":"System","prop":"systemName","width":88 + 96}}),_c('shopee-table-column',{attrs:{"label":"Region","width":80 + 16},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ip || row.region || '-')+" ")]}}])}),_c('shopee-table-column',{attrs:{"label":"Feedback Type","width":168 + 16},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.feedbackTypeEnum[row.feedbackType])+" ")]}}])}),_c('shopee-table-column',{attrs:{"label":"Module","prop":"moduleName","width":47 + 209}}),_c('shopee-table-column',{attrs:{"label":"Description","prop":"description","width":358 + 16},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('expand-ellipsis-text',{attrs:{"content":row.description || '-'}})]}}])}),_c('shopee-table-column',{attrs:{"label":"Status","prop":"status","width":180 + 16},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('shopee-dropdown',{attrs:{"trigger":"hover"}},[_c('div',{class:['status', _vm.statusKeyEnum[row.status]]},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.statusEnum[row.status]))]),_c('shopee-icon',{staticClass:"arrowDownIcon",attrs:{"svg":_vm.arrowDownIcon}})],1),_c('shopee-dropdown-menu',{attrs:{"slot":"dropdown","placement":"bottom"},on:{"click":function (status) { return _vm.onChangeStatus(status, row); }},slot:"dropdown"},_vm._l((_vm.statusEnum),function(value,key){return _c('shopee-dropdown-item',{key:value,attrs:{"name":key}},[_vm._v(" "+_vm._s(value)+" ")])}),1)],1)]}}])}),_c('shopee-table-column',{attrs:{"label":"Submitter Email","prop":"submitter","width":240 + 16}}),_c('shopee-table-column',{attrs:{"label":"Submit Time","width":240 + 16},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getDateTime(row.createTime) || '-')+" ")]}}])}),_c('shopee-table-column',{attrs:{"label":"Attachment","fixed":"right","width":100},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"operator"},[_c('span',{staticClass:"left-action",class:[(!row.attachments || row.attachments.length === 0) && 'disabled'],on:{"click":function($event){return _vm.onDownload(row)}}},[_vm._v(" Download ")])])]}}])}),(_vm.page.total > 0)?_c('shopee-pagination',{staticClass:"pagination",attrs:{"slot":"append","layout":"pages,sizes,jumper","total":_vm.page.total,"current":_vm.page.page,"page-size":_vm.page.pageSize,"page-sizes":_vm.page.sizes},on:{"page-change":_vm.onPageChange,"page-size-change":_vm.onPageSizeChange},slot:"append"}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }